@font-face {
  font-family: 'cawst';
  src: url(/f56a65ccabc9461ecad7bf5c9d81e47f.eot);
  src: url(/f56a65ccabc9461ecad7bf5c9d81e47f.eot) format("embedded-opentype"), url(/e742d0b950a8c8a85465dd4a93d96bfc.ttf) format("truetype"), url(/69f099842e479d2dd29f1cc33372ac06.woff) format("woff"), url(/a37fef81c1d7cc08def24270c7635286.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

i.cawst {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cawst' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cawst-bsf-simple:before {
  content: "\E90E";
}

.cawst-education:before {
  content: "\E90D";
}

.cawst-research:before {
  content: "\E90F";
}

.cawst-bsf:before {
  content: "\E904";
}

.cawst-community:before {
  content: "\E905";
}

.cawst-testing:before {
  content: "\E906";
}

.cawst-hwts:before {
  content: "\E907";
}

.cawst-ict-mobile:before {
  content: "\E908";
}

.cawst-monitoring:before {
  content: "\E909";
}

.cawst-rainwater-harvesting:before {
  content: "\E90A";
}

.cawst-sanitation:before {
  content: "\E90B";
}

.cawst-wash-health:before {
  content: "\E90C";
}

.cawst-online-services-line:before {
  content: "\E901";
}

.cawst-technical-support-line:before {
  content: "\E902";
}

.cawst-training-workshops-line:before {
  content: "\E903";
}

.cawst-logo-drop:before {
  content: "\E900";
}

